import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FootballMatchCard from './FootballMatchCard';
import './App.css';

function App() {
  const [matches, setMatches] = useState([]);
  const [totalMatches, setTotalMatches] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api-football-v1.p.rapidapi.com/v3/fixtures', {
          params: { live: 'all' },
          headers: {
            'X-RapidAPI-Key': 'cb1d7a00b0msh77c745bcc0a0dc0p1620f3jsna3e1b0a585b4', // Replace with your API key
          },
        });

        const { data } = response;
        const matchesWithHighlight = data.response.map((match) => ({
          ...match,
          highlightedGreen:
            match.fixture.status.elapsed >= 60 &&
            ((match.goals.home === 1 && match.goals.away === 0) ||
              (match.goals.home === 0 && match.goals.away === 1)),
        }));

        setMatches(matchesWithHighlight);
        setTotalMatches(matchesWithHighlight.length);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    const refreshTimer = setInterval(() => {
      fetchData();
    }, 30000);

    return () => {
      clearInterval(refreshTimer);
    };
  }, []);

  return (
    <div className="App">
      <h1>Live Football Matches</h1>
      <h2>Total Number of ongoing matches: {totalMatches} matches</h2>
      <div className="match-grid">
        {matches
          .sort((a, b) => (a.highlightedGreen ? -1 : b.highlightedGreen ? 1 : 0))
          .map((match) => (
            <FootballMatchCard key={match.fixture.id} match={match} />
          ))}
      </div>
    </div>
  );
}

export default App;