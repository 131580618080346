import React, { useState, useEffect } from 'react';
import './FootballMatchCard.css';

function FootballMatchCard({ match }) {
  const [showDetails, setShowDetails] = useState(false);
  const [highlightedGreen, setHighlightedGreen] = useState(false);
  const [notificationShown, setNotificationShown] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const playChime = (sound) => {
    const audio = new Audio(sound);
    audio.play();
  };

  useEffect(() => {
    if (!match) {
      return; // Return early if match is undefined
    }

    // Check for time elapsed and score criteria
    if (
      !notificationShown &&
      match.fixture.status.elapsed >= 60 &&
      ((match.goals.home === 1 && match.goals.away === 0) ||
        (match.goals.home === 0 && match.goals.away === 1))
    ) {
      setNotificationShown(true);
      setHighlightedGreen(true); // Highlight in green
      playChime('/notif.wav'); // Chime for notification
      const notificationMessage = `Criteria has been met for the following match: ${match.teams.home.name} vs ${match.teams.away.name}\nScore: ${match.goals.home}-${match.goals.away}`;
      alert(notificationMessage);
    }
  }, [match, notificationShown]);

  return (
    <div className={`match-card ${showDetails ? 'show-details' : ''} ${highlightedGreen ? 'highlighted-green' : ''}`} onClick={toggleDetails}>
      <div className="card-content">
        <div className="team-logo">
          <img src={match.teams.home.logo} alt={`${match.teams.home.name} Logo`} />
          <img src={match.teams.away.logo} alt={`${match.teams.away.name} Logo`} />
        </div>
        <div className="team-names">
          <p>{match.teams.home.name}</p>
          <p>{match.teams.away.name}</p>
        </div>
        <div className="match-details">
          <p>Goals: {match.goals.home} - {match.goals.away}</p>
          <p>Time Elapsed: {match.fixture.status.elapsed}'</p>
        </div>
      </div>
      <div className="additional-details">
        <p>Date: {match.fixture.date}</p>
        <p>Stadium: {match.fixture.venue.name} ({match.fixture.venue.city})</p>
        <p>League: {match.league.name}</p>
        <p>Round: {match.league.round}</p>
        {/* Display more match details here */}
      </div>
    </div>
  );
}

export default FootballMatchCard;